@import '../../styles/variables';

$non-touch-size: 2.2rem;

.Social{
    height: $min-touch-size;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: $small-gap 0 ($med-gap) 0;

    ul{
        margin: 0 auto;
        padding: 0 2.5rem;
        display: flex;
        flex-flow: row nowrap;
        width: 100%;
        height: 100%;
        align-content: stretch;
        justify-content: stretch;
        max-width: 30rem;
    }
    li{
        width: 100%;
        list-style: none;
        height: 100%;
    }
    a{
        color: $light-blue;
        display: flex;
        justify-content: center;
        align-content: center;
        height: $min-touch-size;
        width: 100%;
    }
    svg{
        height: 70%;
        align-self: center;
    }

    @media only screen and (pointer:fine){
        height:  $non-touch-size;

        ul{
            max-width: 25rem;
        }
        a{
            height: $non-touch-size;
        }
    }
}
