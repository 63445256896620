@import '../../../styles/variables';

.Thumb{
    position: relative;
    align-self: center;

    img{
        box-shadow: $card-shadow;
        border: 1px solid rgba($white-color, 0.3);
        position: relative;
        border-radius: 0.3rem;
        cursor: pointer;

        &:hover{
            bottom: $anim-jump-height;
            box-shadow: $lift-shadow;
        }
    }
}
