@import '../../styles/variables';

.Nav{
    background: $black-color;
    box-shadow: 0 0 2rem rgba(0,0,0,0.5);
    z-index: 9900;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}

.NavContent{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: $nav-size;
    width: 100%;
    padding: 0.5rem;

    @media #{$cramped-ceiling}{
        height: $nav-size-reduced;
    }
}

button{
    background: none;
    color: $white-color;
    transition: all ease-in 0.1s;
    height: 100%;
    width: unset;

    @media #{$reduce-motion}{transition: none;}

    svg{
        height: 50%;
        width: unset;
    }

    &:disabled{
        opacity: 0.3;
        cursor: default;
    }

    &:not(:disabled){

        &:hover,
        &:focus{
            opacity: 1;
            color: $main-color;
        }
    }
}

.Logo {
    opacity: 1;
    border: unset;
    display: inline-block;
    height: 100%;
    width: unset;
    margin: 0 2rem;

    svg{
        opacity: 1;
        height: 100%;

        &:not([fill]){
            fill: $main-color;
        }
    }
}

.ArrowPrev{
    svg{transform: rotate(-90deg);}
}

.ArrowNext{
    svg{transform: rotate(90deg);}
}
