@import "../../styles/variables";

$contact-box-breakpoint: 'only screen and (min-width:51rem)';

p,span,div,h1,h2,h3,h4,h5,h6{
    color: $white-color;
    font-family: 'Caladea',serif;
}

.About{
    display: flex;
    flex-flow: column nowrap;
    align-content: center;

    & > p:last-of-type{
        margin-bottom: $big-gap;
    }
}

.Portfolio{
    display: flex;
    flex-flow: column nowrap;
    width: 100%;

    h3{
        margin: 0 auto $med-gap auto;
    }

    .CardContainer{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-gap: 1.25rem;
        margin-bottom: $small-gap;

        @media #{$card-2-column}{
            grid-template-columns: repeat(2, 1fr);
        }

        @media #{$card-3-column}{
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

.Contact{
    p{
        margin: 0 0 $small-gap 0;
    }
}

.ContactContainer{
    display: flex;
    flex-flow: column;
    align-content: center;
    align-items: center;
    justify-content: space-around;
    max-width: 50rem;
    margin: 0 auto $small-gap auto;

    p{
        text-align: center;
    }

    a{
        color: $light-blue;
        background: hsla(222,100,75,0.2);
        padding: 1rem 3rem;
        border: 2px solid $light-blue;
        text-decoration: none;
        display: block;
        border-radius: 0.2rem;
        font-size: $base-font-size;
        margin-top: 2rem;

        &:hover,
        &:focus{
            background: hsla(222,100,75,0.3);
        }
    }

    @media #{$contact-box-breakpoint}{
        flex-flow: row nowrap;

        p{
            max-width: 27.5rem;
            text-align: right;
        }
        a{
            margin-top: 0;
        }
    }
}
