@import '../../../styles/variables';

.Card{
    transition: $basic-transition;

    @media #{$reduce-motion}{transition: none;}

    &:hover{
        img,header{
            position: relative;
            bottom: 0.2rem;
        }
        header{
            box-shadow: $lift-shadow;
            margin-bottom: 0.2rem;
            color: $light-blue;
        }
        .Thumb{
            top: -0.2rem;
            filter: contrast(1);
        }
    }

    header{
        transition: $basic-transition;
        text-align: center;
        background: $black-color;
        margin: 0;
        padding: ($base-font-size / 2);
        font-weight: unset;
        font-size: $base-font-size;
        line-height: $base-font-size;
        box-shadow: $card-shadow;
        border-bottom-left-radius: $card-radius;
        border-bottom-right-radius: $card-radius;
        @media #{$reduce-motion}{transition: none;}
    }
    p{
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        text-align: right;
        color: $med-purple;
        font-size: $card-small-text;
        line-height: $card-small-text;

        svg{
            color: $light-purple;
            height: $card-small-text * 1.3;
            margin-right: $card-small-text;
        }
    }

    @media only screen and (min-width: 600px) {
        flex-flow: row wrap;
    }
}

.Thumb{
    padding-bottom: 60%;
    background-size: cover;
    background-position: center center;
    position: relative;
    transition: $basic-transition;
    filter: contrast(0.8);
    border-top-right-radius: $card-radius;
    border-top-left-radius: $card-radius;
    @media #{$reduce-motion}{transition: none;}

    // WEBP & Fallbacks
    :global(.landman) &{background-image: url(../../../assets/img/portfolio/landman.webp);}
    :global(.no-webp .landman) &{background-image: url(../../../assets/img/portfolio/landman.jpg);}

    :global(.maxq) &{background-image: url(../../../assets/img/portfolio/maxq.webp);}
    :global(.no-webp .maxq) &{background-image: url(../../../assets/img/portfolio/maxq.jpg);}

    :global(.bms) &{background-image: url(../../../assets/img/portfolio/bms.webp);}
    :global(.no-webp .bms) &{background-image: url(../../../assets/img/portfolio/bms.jpg);}

    :global(.outpost) &{background-image: url(../../../assets/img/portfolio/outpost.webp);}
    :global(.no-webp .outpost) &{background-image: url(../../../assets/img/portfolio/outpost.jpg);}

    :global(.userscripts) &{background-image: url(../../../assets/img/portfolio/userscripts.webp);}
    :global(.no-webp .userscripts) &{background-image: url(../../../assets/img/portfolio/userscripts.jpg);}

    :global(.graphics) &{background-image: url(../../../assets/img/portfolio/graphics.webp);}
    :global(.no-webp .graphics) &{background-image: url(../../../assets/img/portfolio/graphics.jpg);}
}
