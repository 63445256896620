@import '../../styles/variables';

.SmallHeader{
    position: relative;
    display: flex;
    flex-flow: column wrap;
    text-align: center;
    align-items: center;
    padding: $med-gap 1rem;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    h2{
        font-size: 12vw;
        line-height: 1;
        color: $white-color;
        text-shadow: $lift-shadow;
        padding: 0 0 $small-gap 0;

        @media only screen and (min-width: 34.5rem){
            font-size: 4rem;
        }
    }

    p{
        color: lighten($light-purple,10%);
        font-style: italic;
        font-weight: bold;
        line-height: $base-font-size;
        margin: 0;
        padding: 0 0 1rem 0;
    }

    ul{
        list-style: circle;
        display: flex;
        flex-flow: row wrap;
        margin: 0;
        padding: 1rem;
        justify-content: center;
    }

    li{
        padding: 0 ($base-font-size/3);
        margin: 0 0.7rem;
        color: $med-purple;
        font-size: $card-small-text;
        line-height: $card-small-text;

        &:first-child{
            list-style-type: none;
        }
    }

    small{
        position: absolute;
        top: 1rem;
        right: 1rem;
        opacity: 0.3;
        font-size: 0.6rem;
        font-family: sans-serif;

        &:hover{
            opacity: 0.7;
        }
    }

    // WEBP & Fallback
    &:global(.landman){background-image: $sh-gradient,url(../../assets/img/backdrop/landman.webp);}
    :global(.no-webp) &:global(.landman){background-image: $sh-gradient,url(../../assets/img/backdrop/landman.webp);}

    &:global(.maxq){background-image: $sh-gradient,url(../../assets/img/backdrop/maxq.webp);}
    :global(.no-webp) &:global(.maxq){background-image: $sh-gradient,url(../../assets/img/backdrop/maxq.webp);}

    &:global(.bms){background-image: $sh-gradient,url(../../assets/img/backdrop/bms.webp);}
    :global(.no-webp) &:global(.bms){background-image: $sh-gradient,url(../../assets/img/backdrop/bms.webp);}

    &:global(.outpost){background-image: $sh-gradient,url(../../assets/img/backdrop/outpost.webp);}
    :global(.no-webp) &:global(.outpost){background-image: $sh-gradient,url(../../assets/img/backdrop/outpost.webp);}

    &:global(.userscripts){background-image: $sh-gradient,url(../../assets/img/backdrop/userscripts.webp);}
    :global(.no-webp) &:global(.userscripts){background-image: $sh-gradient,url(../../assets/img/backdrop/userscripts.webp);}

    &:global(.graphics){background-image: $sh-gradient,url(../../assets/img/backdrop/graphics.webp);}
    :global(.no-webp) &:global(.graphics){background-image: $sh-gradient,url(../../assets/img/backdrop/graphics.webp);}
}
