@import '../../../styles/variables';

.Standout{
    color: $light-purple;
    font-weight: bold;
    font-style: italic;
    font-size: 3.2rem;
    line-height: 3.1rem;
    max-width: 49rem;

    @media only screen and (max-width: 42rem){
        font-size: 2.5rem;
        line-height: 2.4rem;
    }
    @media only screen and (max-width: 30rem){
        font-size: 2rem;
        line-height: 1.9rem;
    }
    @media only screen and (max-width: 24rem){
        font-size: 1.7rem;
        line-height: 1.6rem;
    }
}
